import { Routes, Route } from 'react-router-dom';
import Home from "../pages/Home";
// import CreateProgram from "../pages/CreateProgram";
import ViewExerciseProgram from "../pages/ViewExcerciseProgram/ViewExerciseProgram";
import ExercisePlay from "../pages/ExercisePlay/ExercisePlay";
import ManageExercisePrograms from "../pages/ManageExercisePrograms";
import ManageExercises from "../pages/ManageExercises";

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/create-program" element={<ManageExercisePrograms />} />
            <Route path="/create-exercises" element={<ManageExercises />} />
            <Route path="/view-program/:id" element={<ViewExerciseProgram />} />
            <Route path="/play/:excercise_id" element={<ExercisePlay />} />
        </Routes>
    );
};
