import React, { useState } from 'react';

interface ImageInputProps {
    onAddImage: (imageUrl: string) => void;
}

const ImageInput: React.FC<ImageInputProps> = ({ onAddImage }) => {
    const [imageUrl, setImageUrl] = useState<string>('');

    const handlePaste = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            setImageUrl(clipboardText);
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
        }
    };

    const handleAddImage = () => {
        if (imageUrl) {
            onAddImage(imageUrl);
            setImageUrl('');
        }
    };

    return (
        <div className='grid max-w-[350px]'>
            <div className="flex justify-between space-x-2">
                <input
                    type="text"
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                    placeholder="Cole o link da imagem aqui"
                    className="p-2 border border-gray-300 rounded"
                />
                <button
                    className="bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={handlePaste}
                >
                    Colar
                </button>
            </div>

            <button
                className="bg-green-500 text-white py-2 px-4 rounded mt-2"
                onClick={handleAddImage}
            >
                Adicionar Imagem
            </button>
        </div>
    );
};

export default ImageInput;
