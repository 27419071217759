import { initializeApp, FirebaseApp } from "firebase/app";
import { getFirestore, Firestore } from "firebase/firestore";
import { getAuth, Auth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBIjtjY0JwZEzThw-8Gh10d2MhoxYLV6I0",
    authDomain: "birrl-fit-app.firebaseapp.com",
    projectId: "birrl-fit-app",
};

class Database {
    private static instance: Database | null = null;
    private readonly firebaseApp: FirebaseApp;
    private readonly firestore: Firestore;
    private readonly auth: Auth;

    private constructor() {
        this.firebaseApp = initializeApp(firebaseConfig);
        this.firestore = getFirestore(this.firebaseApp);
        this.auth = getAuth(this.firebaseApp);
    }

    public static factory(): Database {
        if (!Database.instance) {
            Database.instance = new Database();
        }
        return Database.instance;
    }

    public getFirestore(): Firestore {
        return this.firestore;
    }

    public getAuth(): Auth {
        return this.auth;
    }
}

export const database = Database.factory();
