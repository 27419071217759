import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ExerciseRepositoryFactoryV2 from "../backend/repositories/ExerciseRepositoryFactoryV2";
import {ExerciseList} from "../backend/repositories/IExerciseRepositoryV2";

export default function Home() {
    const [exercisePrograms, setExercisePrograms] = useState<ExerciseList[]>([]);
    const [tried, setTried] = useState(false);

    useEffect(() => {
        if (tried) return;

        ExerciseRepositoryFactoryV2
            .factory()
            .getAllExerciseLists()
            .then((programs) => {
                setExercisePrograms(programs);
                setTried(true);
            });
    }, [tried]);

    const handleDeleteProgram = (id: string) => {
        ExerciseRepositoryFactoryV2
            .factory()
            .deleteExerciseList(id)
            .then(() => {
                setExercisePrograms(exercisePrograms.filter(program => program.id !== id));
            });
    };

    return (
        <div className="p-6">

            <h1 className="text-2xl mb-4 mt-4">Programas de Exercícios</h1>

            <div className="mt-4">
                <Link to="/create-program">
                    <button className="bg-blue-500 text-white py-2 px-4 rounded">Gerenciar Programa de exercicios</button>
                </Link>
                <br/>
                <br/>
                <Link to="/create-exercises">
                    <button className="bg-blue-500 text-white py-2 px-4 rounded">Gerenciar exercicios</button>
                </Link>
            </div>
            <hr className='mt-4'/>

            {exercisePrograms.length === 0 ? (
                <p>Nenhum exercício encontrado.</p>
            ) : (
                <div className='mt-4'>
                    {exercisePrograms.map((program) => (
                        <div key={program.id} className="mb-4">
                            <div className="items-center mb-2">
                                <h2 className="text-xl font-bold">{program.title}</h2>
                                <br/>
                                {program.images && program.images.length > 0 && (
                                    <div className="ml-4">
                                        {program.images.map((image, index) => (
                                            <img key={index} src={image} alt="Exercise"
                                                 className="w-20 h-20 object-cover rounded"/>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div className="flex space-x-2">
                                <Link to={`/view-program/${program.id}`}>
                                    <button className="mb-2 bg-blue-500 text-white py-2 px-4 rounded">
                                        Mostrar Exercícios
                                    </button>
                                </Link>
                                <button
                                    className="mb-2 bg-red-500 text-white py-2 px-4 rounded"
                                    onClick={() => handleDeleteProgram(program.id)}
                                >
                                    Apagar Programa
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
