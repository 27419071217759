import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import ExerciseRepositoryFactoryV2 from "../../backend/repositories/ExerciseRepositoryFactoryV2";
import ViewOrEditExcercise from "./ViewOrEditExcercise";
import {ExerciseList} from "../../backend/repositories/IExerciseRepositoryV2";

export default function ViewExerciseProgram() {
    const {id} = useParams();
    const [program, setProgram] = useState<ExerciseList | null>(null);
    const [editingExerciseId, setEditingExerciseId] = useState<string | null>(null);


    useEffect(() => {
        async function fetchProgram() {
            if (!id) return;
            const programData = await ExerciseRepositoryFactoryV2.factory().getExerciseListById(id);
            setProgram(programData || null);
        }

        fetchProgram();
    }, [id]);


    if (!program) {
        return (
            <div className="container mx-auto p-4">
                <p>Carregando programa de exercícios...</p>
            </div>
        )
    }
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">{program.title}</h1>
            <div className="grid grid-cols-3 gap-4">
                {program.images.map((image, idx) => (
                    <img key={idx} src={image} alt={`Program image ${idx + 1}`}
                         className="w-full h-32 object-cover rounded"/>
                ))}
            </div>

            <h2 className="text-xl font-semibold mt-6">Exercícios</h2>
            {program.exerciseIds.map((exercise) => (
                <ViewOrEditExcercise
                    program={program}
                    setProgram={setProgram}
                    exercise={exercise}
                    setEditingExerciseId={setEditingExerciseId}
                    editingExerciseId={editingExerciseId}
                    key={exercise}
                />
            ))}


            <div className="mt-4">
                <Link to="/">
                    <button className="bg-blue-500 text-white py-2 px-4 rounded">Home</button>
                </Link>
            </div>
        </div>
    );
}
