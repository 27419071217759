import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Exercise, ExerciseList} from "../../backend/repositories/IExerciseRepositoryV2";
import ExerciseRepositoryFactoryV2 from "../../backend/repositories/ExerciseRepositoryFactoryV2";

export type ViewOrEditExcerciseType = {
    program: ExerciseList;
    setProgram: (program: ExerciseList) => void;
    exercise: string;
    setEditingExerciseId: (id: string | null) => void;
    editingExerciseId: string | null;
}

export default function ViewOrEditExcercise({
                                                program,
                                                exercise,
                                            }: ViewOrEditExcerciseType) {
    const [exerciseData, setExerciseData] = useState<Exercise | undefined>(undefined);

    useEffect(() => {
        ExerciseRepositoryFactoryV2
            .factory()
            .getExerciseById(exercise)
            .then((exerciseData) => {
                setExerciseData(exerciseData);
            });
    }, [exercise]);

    function removeExcerciseFromProgram(excerciseId: string) {
        const newProgram = {
            ...program,
            exerciseIds: program.exerciseIds.filter(id => id !== excerciseId)
        }

        ExerciseRepositoryFactoryV2
            .factory()
            .updateExerciseList(newProgram)
            .then(() => {
                window.location.reload();
            });

    }

    if(exerciseData === undefined) {
        return <div>Carregando...</div>
    }
    return (
        <div key={exerciseData.id} className="border p-4 rounded mb-4">

            <div>
                <h3 className="font-medium text-lg">{exerciseData.name}</h3>
                <span>{exerciseData.objective}</span>
                <div className="flex mt-2">
                    {exerciseData.images.map((image, idx) => (
                        <img key={`${idx}_images_show`} src={image} alt={`Exercise image ${idx + 1}`}
                             className="w-12 h-12 rounded mr-2"/>
                    ))}
                </div>
                <div className="mt-2 flex gap-2">
                    <Link to={`/play/${exerciseData.id}`}>
                        <button className="bg-green-500 text-white px-2 py-1 rounded">Play</button>
                    </Link>
                    <button className="bg-red-500 text-white px-2 py-1 rounded ml-2" onClick={() => removeExcerciseFromProgram(exerciseData.id)}>Remove</button>
                </div>
            </div>
        </div>
    )
}