import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import ExerciseRepositoryFactoryV2 from "../../backend/repositories/ExerciseRepositoryFactoryV2";
import {Exercise} from "../../backend/repositories/IExerciseRepositoryV2";

const ExercisePlay = () => {
    const {excercise_id} = useParams();
    const navigate = useNavigate();
    const [exercise, setExercise] = useState<Exercise | null>(null);
    const [tried, setTried] = useState(false);


    useEffect(() => {
        if (tried) return;
        if (!excercise_id) return;

        ExerciseRepositoryFactoryV2.factory().getExerciseById(excercise_id).then((exercise) => {
            if (!exercise) return;

            setExercise(exercise);
            setTried(true);
        });

    }, [excercise_id, tried]);

    if (!exercise) {
        return (
            <div>
                <p>Carregando exercício...</p>
                <button
                    onClick={() => navigate(-1)} // Volta para a tela anterior
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                    Voltar
                </button>
            </div>

        );
    }

    return (
        <div className="p-6">
            <div className="modal-content">
                <h2 className="font-bold text-xl">{exercise.name}</h2>
                <p><strong>Equipamento:</strong> {exercise.equipment}</p>
                <p><strong>Objetivo:</strong> {exercise.objective}</p>
                <p><strong>Séries:</strong> {exercise.series}</p>
                <p><strong>Repetições:</strong> {exercise.repetitions}</p>

                <div className="flex max-h-52 overflow-x-scroll overflow-y-hidden">
                    {exercise.images.map((image, index) => (
                        <img key={index} src={image} alt={`exercise-${index}`}
                             className="max-w-['200px'] object-contain"/>
                    ))}
                </div>

                <br/>
                <hr/>
                <br/>

                <ul className="list-disc">
                    {exercise.instructions.map((instruction, index) => (
                        <li key={index}>{instruction}</li>
                    ))}
                </ul>

                <br/>
                <hr/>
                <br/>
                <br/>
                <button
                    onClick={() => navigate(-1)} // Volta para a tela anterior
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                    Voltar
                </button>
            </div>
        </div>
    );
};

export default ExercisePlay;
