import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from "./routes/routes";

export default function App() {
    return (
        <BrowserRouter>
            <div className="min-h-screen bg-gray-100 flex items-center justify-center">
                <div className="container bg-white rounded-lg shadow-md p-6 min-h-screen relative">
                    <AppRoutes />
                </div>
            </div>
        </BrowserRouter>
    );
}

