import { IExerciseRepositoryV2, Exercise, ExerciseList } from './IExerciseRepositoryV2';
import { database } from './Database';
import { doc, setDoc, getDoc, getDocs, deleteDoc, collection } from "firebase/firestore";

export default class FirebaseExerciseRepositoryV2 implements IExerciseRepositoryV2 {

    private static EXERCISES_COLLECTION = 'exercises';
    private static EXERCISE_LISTS_COLLECTION = 'exerciseLists';

    // Collections
    private getExercisesCollection() {
        return collection(database.getFirestore(), FirebaseExerciseRepositoryV2.EXERCISES_COLLECTION);
    }

    private getExerciseListsCollection() {
        return collection(database.getFirestore(), FirebaseExerciseRepositoryV2.EXERCISE_LISTS_COLLECTION);
    }

    // Documents
    private getExerciseDocument(exerciseId: string) {
        return doc(database.getFirestore(), FirebaseExerciseRepositoryV2.EXERCISES_COLLECTION, exerciseId);
    }

    private getExerciseListDocument(listId: string) {
        return doc(database.getFirestore(), FirebaseExerciseRepositoryV2.EXERCISE_LISTS_COLLECTION, listId);
    }

    // Exercícios
    async createExercise(exercise: Exercise): Promise<void> {
        const exerciseDoc = this.getExerciseDocument(exercise.id);
        await setDoc(exerciseDoc, exercise);
    }

    async updateExercise(exercise: Exercise): Promise<void> {
        const exerciseDoc = this.getExerciseDocument(exercise.id);
        await setDoc(exerciseDoc, exercise);
    }

    async deleteExercise(exerciseId: string): Promise<void> {
        const exerciseDoc = this.getExerciseDocument(exerciseId);
        await deleteDoc(exerciseDoc);
    }

    async getExerciseById(exerciseId: string): Promise<Exercise | undefined> {
        const exerciseDoc = this.getExerciseDocument(exerciseId);
        const snapshot = await getDoc(exerciseDoc);
        return snapshot.exists() ? (snapshot.data() as Exercise) : undefined;
    }

    async getAllExercises(): Promise<Exercise[]> {
        const querySnapshot = await getDocs(this.getExercisesCollection());
        return querySnapshot.docs.map(doc => doc.data() as Exercise);
    }

    // Listas de exercícios
    async createExerciseList(list: ExerciseList): Promise<void> {
        const listDoc = this.getExerciseListDocument(list.id);
        await setDoc(listDoc, list);
    }

    async updateExerciseList(list: ExerciseList): Promise<void> {
        const listDoc = this.getExerciseListDocument(list.id);
        await setDoc(listDoc, list);
    }

    async deleteExerciseList(listId: string): Promise<void> {
        const listDoc = this.getExerciseListDocument(listId);
        await deleteDoc(listDoc);
    }

    async getExerciseListById(listId: string): Promise<ExerciseList | undefined> {
        const listDoc = this.getExerciseListDocument(listId);
        const snapshot = await getDoc(listDoc);
        return snapshot.exists() ? (snapshot.data() as ExerciseList) : undefined;
    }

    async getAllExerciseLists(): Promise<ExerciseList[]> {
        const querySnapshot = await getDocs(this.getExerciseListsCollection());
        return querySnapshot.docs.map(doc => doc.data() as ExerciseList);
    }
}
